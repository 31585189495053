/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
@import "commonstyle";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");

body:not(.menu-on-top).desktop-detected {
	min-height: auto !important;
	overflow: hidden;
}
body:not(.menu-on-top).mobile-detected {
    min-height: auto !important;
	overflow: hidden;
}
body {
	background: unset;
	background-color: #202124;
}

#content {
	padding: 20px !important;
	max-height: calc(100vh - 100px);
	overflow-y: auto;
}

.page-footer {
	/* padding-left: 0px; */
	z-index: 0;
	border-top: none;
}

// #logo {
// 	background-repeat: no-repeat;
// 	background-size: 100% 100%;
// 	height: 40px !important;
// 	width: 135px;
// 	display: block;
// 	margin-top: 0px;
// 	margin-left: 36px;
// }
#logo {
    margin: 0;
    vertical-align: middle;
    display: table-cell;
    padding: 7px;
	width: 175px;
	img {
		width: 100%;
		padding-left: 0;
		height: 48px;
		vertical-align: middle;
		object-fit: contain;
	}
}

.setnavBackgroundImg {
	// border-radius: 100%;
	// box-shadow: 5px 10px 75px rgba(0, 0, 0, .4);
	position: relative;
	z-index: 2;
	max-width: none;
	width: 90px;
	height: 90px;
	cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
	height: 110px;
	width: 110px;
}

.col-xs-10 {
	width: 83.33333333% !important;
}

.col-xs-2 {
	width: 16.66666667% !important;
}

.col-xs-3 {
	width: 25% !important;
}

.col-xs-7 {
	width: 58.33333333% !important;
}

.col-xs-8 {
	width: 66.66666667% !important;
}

.col-xs-6 {
	width: 50% !important;
}

.col-xs-1 {
	width: 8.33333333% !important;
}

.col-xs-4 {
	width: 33.33333333% !important;
}

.col-xs-5 {
	width: 41.66666667% !important;
}

#extr-page {
	overflow-x: hidden;

	// #header {
		// #logo {
		// 	img {
		// 		width: 200px;
		// 	}

		// 	margin-left: 75px !important;
		// 	margin-top: 10px !important;
		// }
	// }
}

.notification-body {
	.unread {
		background: $table-bgcolor;
	}

	li {
		span {
			background: $secondaryFocus !important;
		}
	}
}

.li-footer__copy-text {
	color: #8B91A0;
}

.registeContent {
	max-height: calc(100vh);
}

// * {
//     padding: 0;
//     margin: 0;
//   }
.loader-outer {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
}

//   body {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100vh;
//     width: 100%;
//     background: #000;
//   }

.loader>span {
	width: 15px;
	height: 15px;
	display: block;
	background: #BEEB20;
	border-radius: 50%;
	position: relative;
	margin: 0 5px;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dot-1 {
	animation: anim 1s linear 0s infinite;
}

.dot-2 {
	animation: anim 1s linear 0.25s infinite;
}

.dot-3 {
	animation: anim 1s linear 0.50s infinite;
}

.dot-4 {
	animation: anim 1s linear 0.75s infinite;
}

@keyframes anim {
	0% {
		top: 0;
	}

	50% {
		top: 15px;
	}

	100% {
		top: 0;
	}
}

@media (max-width: 1161px) and (min-width: 300px) {
	#extr-page #header #logo img {
		width: 150px;
	}

	#extr-page #header #logo {
		margin-left: 10px !important;
		margin-top: 20px !important;
	}
}

@media only screen and (max-width: 280px) {

	#header>:first-child,
	aside {
		width: 150px;
	}

	#extr-page #header #logo img {
		width: 125px;
	}

	#extr-page #header #logo {
		margin-left: 10px !important;
		margin-top: 20px !important;
	}
}

#tile1,
#tile2,#tile3 {
	width: 65px;
	height: 65px;
	cursor: pointer;
	background-repeat: no-repeat;
	/* margin-left: 25px; */
	display: flex;
	margin: 1%;
}

.logoutIcon {
	font-size: 20px;
}


/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {

	#logo-group {
		background: unset !important;
	}

	// #logo {
	// 	background-image: url(/assets/img/svgImages/logo.svg);
	// }

	.btn-header {

		&:first-child {
			a {
				background: unset;
				border: 1px solid $border-color;
			}
		}
	}

	#header {
		background-color: #202124;
	}

	aside {
		background-color: #171819;
		// border-right: 1px solid #666666 !important;
	}

	.page-footer {
		background-color: #202124;
	}

	#ribbon {
		background: $tabsborder-color;
	}

	.ajax-dropdown {
		background-color: $secondaryFocus !important;
	}

	.ajax-notifications {
		background-color: $secondaryFocus !important;
	}

	.header-dropdown-list {
		a {
			&:hover {
				color: $primary-color !important;
			}
		}
	}

	.setnavBackgroundImg,
	.setprfBackgroundImg {
		background-image: url(/assets/img/svgImages/Group348.svg);

	}

	.setBackgroundCompanylogoImg {
		background-image: url(/assets/img/companylogo.png);

	}

	#tile1 {
		background-image: url(/assets/img/invoice_orange.svg);
	}
	#tileItems1:hover{
		#tile1 {
			background-image: url(/assets/img/right-circle-arrow.svg)
		}
	}

	#tile2 {
		background-image: url(/assets/img/filemanager_orange.svg);
	}
	#tile3 {
		background-image: url(/assets/img/Contract_orange.svg);
	}
	#tileItems2:hover{
		#tile2 {
			background-image: url(/assets/img/right-circle-arrow.svg);
		}
	}
	#tileItems3:hover{
		#tile3 {
			background-image: url(/assets/img/right-circle-arrow.svg);
		}
	}
	.commonTile {
		border: 1px solid #b6eb00 !important;

		.txtSpan {
			color: #fff;
		}
	}

	.commonTile:hover {

		// background-color:#b6eb00!important;
		.txtSpan {
			color: #000 !important;
		}
	}

	.dashboard_Header {
		.dashHeadLbl {
			color: #fff;
		}
	}

	.logoutIcon {
		color: #fff !important;
	}

}

/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {

	// #logo {
	// 	background-image: url(/assets/img/svgImages/logoWhite.svg);
	// 	margin-top: 4px;
	// }

	.login-info {
		color: #212529;
	}

	aside {
		color: #fff !important;
		// background: #171819 !important;
		background-image: linear-gradient(#666666, #666666) !important;
	}

	#header {
		background-color: $primary-color;
	}

	nav ul .active>a {
		color: #202427 !important;
		font-weight: 400 !important;
		font-size: 14px !important;
	}

	.setnavBackgroundImg,
	.setprfBackgroundImg {
		background-image: url(/assets/img/svgImages/Group349.svg);
	}

	.setBackgroundCompanylogoImg {
		background-image: url(/assets/img/companylogo.png);
	}

	#tile1 {
		background-image: url(/assets/img/invoice.svg);

	}

	#tile2 {
		background-image: url(/assets/img/filemanager.svg);

	}
	#tile3 {
		background-image: url(/assets/img/Contract.svg);

	}

	.commonTile {
		border: 1px solid #b6eb00 !important;

		.txtSpan {
			color: #212529;
		}
		.tileItems{
			background-color: #ededed!important;
		}

	}

	.ratingSection {
        .prd_Item {
            color: #000 !important;
        }
        .rating_subscriber {
            color: #000 !important;
        }
    }

	.availableDeviceList {
		span {
			color: #000 !important;
		}
	}
	.descSection,.featureLbl,.featureList,.dash_head{
		color: #000 !important;
	}

	.commonTile:hover {

		// background-color:#b6eb00!important;
		.txtSpan {
			color: #000 !important;
		}
	}

	.dashHeadLbl {
		color: #212529 !important;
	}


	#tile1:hover, #tile2:hover, #tile3:hover  {
		background-image: url(/assets/img/right-circle-arrow.svg);
	}

	// .tileSectionTop {
	// 	border: 1px solid #ccc !important;
	// }
	.logoutIcon {
		color: #000 !important;
	}

}

/*
---------------------------------------------------------------------------------------
    White Dark theme style
---------------------------------------------------------------------------------------
*/

@media (max-width: 992px) {
	#content {
		max-height: calc(100vh - 130px) !important;
	}
}

@media (max-width: 576px) {
	#content {
		max-height: calc(100vh - 180px) !important;
	}
}